'use client';
import Button from '@/components/atoms/Button';
import Image from 'next/image';
import Link from 'next/link';

const error = ({ error, reset }: { error: Error; reset: () => void }) => (
	<div
		className='container'
		style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: '100px',
			height: '700px'
		}}>
		<Image unoptimized src='/images/500.svg' alt='500' width={700} height={700} objectFit='contain' />
		<h2 className='pt-10'>There was a problem</h2>
		<span>{'Something went wrong'}</span>
		<p>Please try again later or contact support if the problem persists</p>
		<span>Please go back to the home. </span>

		<Button className='mt-20'>
			<Link href='/'>Home</Link>
		</Button>
	</div>
);
export default error;
